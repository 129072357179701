@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css */
.payment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .payment-details {
    display: flex;
    align-items: flex-start;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
  }
  
  .user-info {
    flex: 1;
  }
  
  .paypal-button-wrapper {
    margin-left: 20px;
    align-self: flex-start;
  }
  
  .loading-message {
    font-size: 1.2em;
    color: #555;
    text-align: center;
    padding: 20px;
  }
  